import  timelineElementsEn  from '../Data/Timeline/timelineElementsEn';
import profileElementEn from '../Data/Profile/profileElementEn';
import techElementEn from '../Data/Technology/techElementEn';

let localEn={

    timeline:timelineElementsEn,
    profile:profileElementEn,
    technology:techElementEn,
}

export default localEn;