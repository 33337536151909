import  timelineElementsTR  from '../Data/Timeline/timelineElementsTR';
import profileElementTR from '../Data/Profile/profileElementTR';
import techElementTR from '../Data/Technology/techElementTR';

let localTR={

    timeline:timelineElementsTR,
    profile:profileElementTR,
    technology:techElementTR,
}

export default localTR;