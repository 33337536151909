import Resume from '../../../Img/Resume.pdf'

let profileElementEn = {
    name: "Atakan GÖÇER",
    job: "Computer Engineer",
    github: "https://github.com/Atakan-droid",
    linkedin: "https://www.linkedin.com/in/atakan-göçer-8781701a0/",
    behance: "https://www.behance.net/atakanger",
    aboutMe: "About Me",
    description: "I am a person who has graduated from Sakarya University Computer Engineering Department and is interested in mobile and web technologies. I aim to learn and teach new things together with developers interested in these technologies. I share the applications that I have developed and will develop for suggestions and advice as open source. I am doing my best to reach my goal.",
    sideDescription: "SOLID - Layered Architecture - OOP- AOP",
    downloadText: "Click to download resume...",
    downloadFileName: "Resume.pdf",
    downloadFile: Resume,
    openResume: "Click to open resume..."
}

export default profileElementEn;